<template>
  <div class="tab_container">
    <div class="solution_part">
      <h2>{{ title }}</h2>
      <h4 class="mar-24">{{ subTitle }}</h4>
      <div class="tab_warp">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ tab_item: true, check: current == index }"
          @click="current = index"
        >
          {{ item }}
        </div>
      </div>

      <div class="content" v-show="current == 0">
        <div class="cockpit_text">
          <h3>纵观全局，辅助决策</h3>
          <p>
            覆盖企业多项数据、经营看板、指挥中心等场景，核心数据指标实时可见，助力企业科学决策。
          </p>
          <h3>数字化赋能组织，释放重复性人工</h3>
          <p>
            支持不限层次的数据钻取和多维度联动分析，协助用户更好地分析问题。
          </p>
          <h3>提升资产效率</h3>
          <p>
            识别优质资产挖掘升值空间，以真实数据作为依托，做出最合理的资产运营和租赁决策，提高资产价值。
          </p>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </div>
        <div class="img-warp2">
          <img
            src="@/assets/image/ProductApartment/tab-1.png"
            width="100%"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="content" v-show="current == 1">
        <div class="cockpit_text">
          <h3>合同全生命周期在线管控</h3>
          <p>
            在线管控合同全生命周期，实现审批、智能生成收付款计划；到期智能预警，到期提醒，催缴款提醒等。
          </p>
          <h3>挖掘增值服务，风险智能预警</h3>
          <p>
            实时了解租客信息，提前洞察租客的续租意向、退租几率、做好运营动作，智能预警租赁异常情况，规避风险。
          </p>
          <h3>资产管理数据化，资产数据可视化</h3>
          <p>
            资产经营可视化看板，随时可以查看经营全貌，实现资产运营健康度的全方位动态评估、监测。
          </p>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/tab-2.png" alt="" />
        </div>
      </div>
      <div class="content" v-show="current == 2">
        <div class="cockpit_text">
          <h3>财务摊销自动化</h3>
          <p>
            自动生成各期应收账单数据，同时根据财务逻辑设定，自动计算摊销数额生成计划收入表。
          </p>
          <h3>缴款与催款自动提醒</h3>
          <p>
            租金账单一键同步给财务和业务部门，自动向租客推送交租信息，明确催收职责划分。
          </p>
          <h3>财务凭证自动化</h3>
          <p>
            基于账单台账，完成收款，退款，抵扣，结佣等操作，每一步都会记录存档，提供对应的凭证单据以供溯源。
          </p>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/tab-3.png" alt="" />
        </div>
      </div>
      <div class="content" v-show="current == 3">
        <div class="cockpit_text">
          <h3>租控房态图实时更新房源动态</h3>
          <p>可视化动态租控，实时更新房源动态，解决信息同步严重滞后的难题。</p>
          <h3>客户在线查看房源详情，随时在线沟通</h3>
          <p>
            搭建线上租客小程序，打通微信、支付宝、抖音、小红书等主流社交平台。
          </p>
          <h3>全平台、全民营销</h3>
          <p>
            房源一键同步至贝壳找房、58找房等平台。支持全民分销，获取更多租客流量、中介流量；
          </p>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/tab-4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution",
  props: {
    title: String,
    subTitle: String,
  },
  data() {
    return {
      current: 0,
      tabList: [
        "管理人员的驾驶舱",
        "运营人员的工作智襄",
        "财务人员的贴心助理",
        "租客服务的观测台",
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.mar-24 {
  margin: 0 24px;
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 36px;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
  }
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.btn-size {
  width: 160px;
  height: 48px;
  font-size: 16px;
  margin: 24px 0;
}
.tab_container {
  width: 100%;
  .tab_warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4edfc;
  }
  .tab_item {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;

    cursor: pointer;
    width: 200px;
  }
  .check {
    position: relative;
  }
  .check::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    background: #0060ff;
  }

  .content {
    margin: 47px 0 70px 0;
    display: flex;
    .img-warp2{
      width: 662px;
      margin-left: 30px;
      flex-shrink: 0;
    }
    .img-warp {
      flex-shrink: 0;
      width: 662px;
      // height: 432px;
      margin-left: 30px;
      position: relative;
      img {
        width: 100%;
      }
    }

    .cockpit_text {
      // margin-top: 12px;
      text-align: left;
      h3 {
        color: #030d1e;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
      }
    }
    .img-2-1 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 600px;
    }
    .img-2-2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 600px;
    }
  }
}
@media screen and (max-width: 768px) {
  .solution_part {
    width: 100%;
    padding-bottom: 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
    }
  }

  .tab_container {
    .tab_warp {
      justify-content: space-evenly;
      flex-wrap: wrap;
      .tab_item {
        width: 128px;
        font-size: 12px;
        margin-top: 12px;
      }
      .check::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: #0060ff;
      }
    }
    .content {
      margin: 14px 12px;
      display: block;
      .btn-size {
        height: 29px;
        width: 72px;
        font-size: 12px;
        margin: 24px auto;
      }
      .cockpit_text {
        h3 {
          font-size: 14px;
          text-align: center;
          margin: 20px 0 5px 0;
        }
        p {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
        }
      }
      .img-warp2{
        // height: 216px;
        width: 350px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .img-warp {
        height: 216px;
        width: 350px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
