var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_container"},[_c('div',{staticClass:"solution_part"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('h4',{staticClass:"mar-24"},[_vm._v(_vm._s(_vm.subTitle))]),_c('div',{staticClass:"tab_warp"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:{ tab_item: true, check: _vm.current == index },on:{"click":function($event){_vm.current = index}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 0),expression:"current == 0"}],staticClass:"content"},[_vm._m(0),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 1),expression:"current == 1"}],staticClass:"content"},[_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 2),expression:"current == 2"}],staticClass:"content"},[_vm._m(4),_vm._m(5)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 3),expression:"current == 3"}],staticClass:"content"},[_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("纵观全局，辅助决策")]),_c('p',[_vm._v(" 覆盖企业多项数据、经营看板、指挥中心等场景，核心数据指标实时可见，助力企业科学决策。 ")]),_c('h3',[_vm._v("数字化赋能组织，释放重复性人工")]),_c('p',[_vm._v(" 支持不限层次的数据钻取和多维度联动分析，协助用户更好地分析问题。 ")]),_c('h3',[_vm._v("提升资产效率")]),_c('p',[_vm._v(" 识别优质资产挖掘升值空间，以真实数据作为依托，做出最合理的资产运营和租赁决策，提高资产价值。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp2"},[_c('img',{attrs:{"src":require("@/assets/image/ProductApartment/tab-1.png"),"width":"100%","alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("合同全生命周期在线管控")]),_c('p',[_vm._v(" 在线管控合同全生命周期，实现审批、智能生成收付款计划；到期智能预警，到期提醒，催缴款提醒等。 ")]),_c('h3',[_vm._v("挖掘增值服务，风险智能预警")]),_c('p',[_vm._v(" 实时了解租客信息，提前洞察租客的续租意向、退租几率、做好运营动作，智能预警租赁异常情况，规避风险。 ")]),_c('h3',[_vm._v("资产管理数据化，资产数据可视化")]),_c('p',[_vm._v(" 资产经营可视化看板，随时可以查看经营全貌，实现资产运营健康度的全方位动态评估、监测。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/tab-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("财务摊销自动化")]),_c('p',[_vm._v(" 自动生成各期应收账单数据，同时根据财务逻辑设定，自动计算摊销数额生成计划收入表。 ")]),_c('h3',[_vm._v("缴款与催款自动提醒")]),_c('p',[_vm._v(" 租金账单一键同步给财务和业务部门，自动向租客推送交租信息，明确催收职责划分。 ")]),_c('h3',[_vm._v("财务凭证自动化")]),_c('p',[_vm._v(" 基于账单台账，完成收款，退款，抵扣，结佣等操作，每一步都会记录存档，提供对应的凭证单据以供溯源。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/tab-3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("租控房态图实时更新房源动态")]),_c('p',[_vm._v("可视化动态租控，实时更新房源动态，解决信息同步严重滞后的难题。")]),_c('h3',[_vm._v("客户在线查看房源详情，随时在线沟通")]),_c('p',[_vm._v(" 搭建线上租客小程序，打通微信、支付宝、抖音、小红书等主流社交平台。 ")]),_c('h3',[_vm._v("全平台、全民营销")]),_c('p',[_vm._v(" 房源一键同步至贝壳找房、58找房等平台。支持全民分销，获取更多租客流量、中介流量； ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/tab-4.png"),"alt":""}})])
}]

export { render, staticRenderFns }