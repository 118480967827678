<template>
  <div class="page">
    <div class="bg_wrap">
      <h3>寓信PMS</h3>
      <h1>
        智能的管理工具 丰富的
        <br />
        实践方法
      </h1>
      <h4>全面推进住房租赁行业数字化升级</h4>
      <div class="btn_warp">
        <a-space size="large">
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">预约演示</div>
          </a>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button plain btn-size">免费试用</div>
          </a>
          <!-- <div class="button plain btn-size">免费试用</div> -->
          <!-- <a-button type="primary" block size="large">预约演示</a-button>
          <a-button type="primary" block size="large" ghost>免费试用</a-button> -->
        </a-space>
      </div>
    </div>
    <div class="solution_part">
      <h2>产品价值</h2>
      <h4>面向全行业的资产管理系统，帮助企业提升资产运营效率</h4>
      <a-row :gutter="[24, { xs: 12, sm: 12, md: 24 }]">
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon1.png" alt="" />
            <div class="item_left">
              <h4>助力组织提效</h4>
              <p>
                让“人”更灵活地连接事、管理
                事，让各类信息、数据无界流通，从而实现组织效率提升。
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon2.png" alt="" />
            <div class="item_left">
              <h4>沉淀资产价值</h4>
              <p>
                充分了解自持资产的实时运营情况，以真实数据作为依托，做出最合理的决策，提高资产价值。
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon3.png" alt="" />
            <div class="item_left">
              <h4>科学赋能决策</h4>
              <p>
                基层用数据发现问题、中层用数据发现规律、高层用数据发现机会、决策层用数据发现趋势。
              </p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="solution_part">
      <h2>体验更好的寓信PMS</h2>
      <h4 class="mar-24">
        打通从获客到租后服务管理的全流程，提供多维度数据洞见，助力管理者科学决策
      </h4>
      <a-row :gutter="[{ xs: 12, sm: 12, md: 24 }, 24]" class="mar-flanks-6">
        <a-col :span="6" :md="6" :sm="12" :xs="12">
          <div class="board_warp">
            <div class="board_top blue">
              <h3>引流获客</h3>
              <p>多渠道引流，降低获客成本</p>
            </div>
            <div class="board_bottom">
              <h4>一键全渠道获客</h4>
              <div class="board_sbutile">品牌搭建，流量矩阵</div>
              <div class="label_warp">
                <p>唯一打通微信、抖音、支付宝、小红书等社交媒体</p>
                <img
                  src="../assets/image/ProductApartment/board_lable.png"
                  alt=""
                />
              </div>
              <div class="board_sbutile">OTA直连，一键发房</div>
              <div class="label_warp">
                <p>集成三方OTA房源分发平台</p>
                <img
                  src="../assets/image/ProductApartment/house_msg.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="6" :md="6" :sm="12" :xs="12">
          <div class="board_warp">
            <div class="board_top green">
              <h3>租约管控</h3>
              <p>全面掌控房源状态与运营指标</p>
            </div>
            <div class="board_bottom">
              <h4>租约全生命周期</h4>
              <div class="board_sbutile">租约状态管控</div>
              <div class="label_warp text_warp">
                <span>合同新签</span>
                <span>合同变更</span>
                <span>合同履约</span>
                <span>合同违约</span>
                <span>合同续签</span>
                <span>合同退租</span>
                <span>合同到期</span>
                <span>合同归档</span>
              </div>
              <div class="board_sbutile mt_35">租金账单管控</div>
              <div class="label_warp text_warp">
                <span>在线支付</span>
                <span>智能分账</span>
                <span>账单生成</span>
                <span>自动核销</span>
                <span>发票开具</span>
                <span>财务凭证</span>
                <span>费用提醒</span>
                <span>系统集成</span>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="6" :md="6" :sm="12" :xs="12">
          <div class="board_warp">
            <div class="board_top orange">
              <h3>运营管理</h3>
              <p>精细化业务运营与数据洞察</p>
            </div>
            <div class="board_bottom">
              <h4>服务切入提高效益</h4>
              <div class="board_sbutile">租客服务在线化</div>
              <div class="label_warp text_warp">
                <span>维修服务</span>
                <span>保洁服务</span>
                <span>评价投诉</span>
                <span>政策申报</span>
                <span>活动报名</span>
                <span>账单提醒</span>
              </div>
              <div class="board_sbutile">水电收缴智能化</div>
              <div class="board_sbutile" style="margin-top: 20px">
                服务管理智能化
              </div>
              <div class="label_warp text_warp">
                <span>智能预警</span>
                <span>客情维护</span>
                <span>设备联动</span>
                <span>贴心提醒</span>
                <span>行为指导</span>
                <span>安全保障</span>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="6" :md="6" :sm="12" :xs="12">
          <div class="board_warp">
            <div class="board_top subblue">
              <h3>资产经营</h3>
              <p>查流失、控风险、促增长</p>
            </div>
            <div class="board_bottom">
              <h4>用数据精准决策</h4>
              <div class="board_sbutile">资产评估</div>
              <div class="label_warp text_warp">
                <span>租金收益</span>
                <span>效益流失</span>
                <span>租金坪效</span>
                <span>现金流量</span>
              </div>
              <div class="board_sbutile">资产评估</div>
              <div
                class="label_warp text_warp"
                style="padding: 10px 20px; justify-content: center"
              >
                精准描绘租客画像
              </div>
              <div class="board_sbutile">资产经营分析</div>
              <div class="label_warp text_warp">
                <span>营收分析</span>
                <span>租约分析</span>
                <span>房源分析</span>
                <span>流转效率</span>
                <span>服务分析</span>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div style="background: #f5f6fa">
      <apartment
        :title="solution.title"
        :subTitle="solution.subTitle"
      ></apartment>
    </div>
    <!-- <div class="tab_container">
      <div class="solution_part">
        <h2>覆盖企业全业务场景，让员工在协同中走向高效</h2>
        <h4>寓信科技为公寓企业中的每个角色构建，适配全部场景</h4>
        <div class="tab_warp">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ tab_item: true, check: current == index }"
            @click="current = index"
          >
            {{ item }}
          </div>
        </div>

        <div class="content">
          <div class="cockpit_text">
            <h3>纵观全局，辅助决策</h3>
            <p>
              覆盖企业多项数据、经营看板、指挥中心等场景，核心数据指标实时可见，助力企业科学决策。
            </p>
            <h3>数字化赋能组织，释放重复性人工</h3>
            <p>
              支持不限层次的数据钻取和多维度联动分析，协助用户更好地分析问题。
            </p>
            <h3>提升资产效率</h3>
            <p>
              识别优质资产挖掘升值空间，以真实数据作为依托，做出最合理的资产运营和租赁决策，提高资产价值。
            </p>
            <a-button
              type="primary"
              size="large"
              style="height: 48px; width: 160px"
              >立即体验</a-button
            >
          </div>
          <img
            src="../assets/image/ProductApartment/screen.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div> -->
    <div class="solution_part">
      <h2>寓信特有产品服务，更便捷的数字化体验</h2>
      <h4 class="mar-24">
        丰富灵活定制开放能力，流畅便捷的开发体验，帮助企业与寓信PMS深度融合
      </h4>
      <div class="serve_warp">
        <div class="serve_item" v-for="(item, index) in serveList" :key="index">
          <img :src="item.icon" alt="" />
          <h4>{{ item.title }}</h4>
          <p>{{ item.text }}</p>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即体验</div>
      </a>
    </div>
    <div class="solution_part bottom_warp">
      <h2>更懂企业的管理痛点，陪伴客户走向成功</h2>
      <h4>专家团队实践经验丰富，提供一站式咨询服务</h4>
    </div>
    <div class="bottom_bg">
      <div class="exhibition_warp">
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom1.png" alt="" />
          <div class="exhibition_text">
            <h4>售前咨询</h4>
            <p>
              资深的行业方案专家
              <br />
              根据您的诉求设计解决方案
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom2.png" alt="" />
          <div class="exhibition_text">
            <h4>实施上线</h4>
            <p>
              专业的实施团队，根据企业的业务场景
              <br />
              进行安装部署与系统配置
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom3.png" alt="" />
          <div class="exhibition_text">
            <h4>售后及运营</h4>
            <p>
              可靠的售后及运营团队，提供使用培训与
              <br />
              案例分享，持续为企业迭代产品和服务
            </p>
          </div>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即咨询</div>
      </a>
    </div>
    <!-- <div class="container">
      <div class="title_con">
        <h1>技术实施解决方案</h1>
        <h4>根据需求自定义采购，全面助力长短租公寓企业信息化升级</h4>
      </div>
      <solution-component class="pc_solution"></solution-component>
    </div>
    <div class="mobile_solution">
      <div class="item">
        <img src="../assets/image/homebg_1.png" alt="" />
        <h3>云部署解决方案</h3>
        <p>
          依托强大的云计算能力，为企业提供一站式服务，无需下载安装，无需适配不同的软硬件平台，即可轻松使用标准版系统，实现高效管理。适用于中型公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_3.png" alt="" />
        <h3>私有化解决方案</h3>
        <p>
          依托企业自有平台，根据企业需求将其部署到指定的云服务器，包括管理系统、官网、官微、小程序等，形成自己的数据库，实现特色化管理。适用于中大型公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_5.png" alt="" />
        <h3>轻定制解决方案</h3>
        <p>
          基于私有化解决方案增加定制服务，根据企业需求可对数据报表、支付系统、智能硬件等模块进行单独定制，适用于有清晰业务模式的品牌公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_7.png" alt="" />
        <h3>全定制解决方案</h3>
        <p>
          组建行业资深专家组，深入企业内部全面了解业务场景，针对企业自身的业务痛点，打造专属化的解决方案，打造成企业特有的品牌。适用于大型或集团型企业。
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import apartment from "../components/product/apartment.vue";
// import SolutionItem from "../components/SolutionItem";
export default {
  name: "Solution",
  components: {
    apartment,
  },
  data() {
    return {
      solution: {
        title: "覆盖企业全业务场景，让员工在协同中走向高效",
        subTitle: "寓信科技为公寓企业中的每个角色构建，适配全部场景",
      },
      // current: 0,
      // tabList: [
      //   "管理人员的驾驶舱",
      //   "运营人员的工作智襄",
      //   "财务人员的贴心助理",
      //   "租客服务的观测台",
      // ],
      serveList: [
        {
          icon: require("../assets/image/ProductApartment/serve1.png"),
          title: "全域获客",
          text: "全域客户跨平台打通客户分层精细营销",
        },
        {
          icon: require("../assets/image/ProductApartment/serve2.png"),
          title: "可视化大屏",
          text: "轻松制作酷炫驾驶舱模板，同步数据大屏集中管控",
        },
        {
          icon: require("../assets/image/ProductApartment/serve3.png"),
          title: "经营数据看板",
          text: "全方位运营数据展示 赋能企业科学决策",
        },
        {
          icon: require("../assets/image/ProductApartment/serve4.png"),
          title: "IoT智能设备平台",
          text: "提供云端一体的设备智能化服务，快速实现智能设备管控",
        },
        {
          icon: require("../assets/image/ProductApartment/serve5.png"),
          title: "业财一体化",
          text: "打通业务、财务间的完整数据链条，解决信息孤岛",
        },
        {
          icon: require("../assets/image/ProductApartment/serve6.png"),
          title: "资产盘点",
          text: "从入、领、调、盘、修、废，实现固定资产全流程闭环管理",
        },
        {
          icon: require("../assets/image/ProductApartment/serve7.png"),
          title: "政府监管平台",
          text: "已接入深圳、广州、北京、杭州、郑州等政府监管平台",
        },
        {
          icon: require("../assets/image/ProductApartment/serve8.png"),
          title: "三方系统集成对接",
          text: "提供全面的开放能力与集成方案，助力企业系统深度融合",
        },
      ],
    };
  },
  created() {
    // this.$store.commit('changeOpacity',false)
  },
  methods: {
    lookDetails(name) {
      this.$router.push({ name: "solutionDetails", params: { name: name } });
    },
  },
};
</script>

<style lang="less" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.btn-size {
  height: 50px;
  width: 160px;
  font-size: 18px;
}
.mar-auto {
  margin: 0 auto;
}
.bg_wrap {
  height: 550px;
  padding-top: 70px;
  background: url("../assets/image/ProductApartment/banner_bg.png") no-repeat
    center;
  background-size: cover;
  background-position: 100% 100%;
  h1 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 44px;
    font-weight: bold;
  }
  h3 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 116px;
    // font-weight: 600;
    color: #2267f6;
  }
  h4 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
  }
  .btn_warp {
    width: 1200px;
    margin: 31px auto;
  }
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  .type-list {
    padding: 0 50px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: #030d1e;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
  }
  .bottom_warp {
    padding-bottom: 35px;
  }
  .product_item {
    display: flex;
    padding: 42px 32px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 56px;
      // width: 56px;

      margin-right: 29px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 7px;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 16px;
      color: #808082;
      text-align: left;
    }
  }
  .board_warp {
    // margin:0 12px;
    .board_top {
      padding: 20px 0;
      color: #ffffff;
      h3 {
        color: #ffffff;
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .blue {
      background: #0077ff;
      box-shadow: 0px 7px 29px 0px #a4ccfa;
    }
    .green {
      background: #30c758;
      box-shadow: 0px 7px 29px 0px #a1ffba;
    }
    .orange {
      background: #ff7f0f;
      box-shadow: 0px 7px 29px 0px #fdc89a;
    }
    .subblue {
      background: #00ccff;
      box-shadow: 0px 7px 29px 0px #d8f7ff;
    }
    .board_bottom {
      height: 532px;
      margin: 40px 0 20px 0;
      background: #f1f5fb;
      border: 1px solid #fcfefd;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
      padding: 0 26px;
      h4 {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 20px;
        margin: 25px 0 10px 0;
      }
      .board_sbutile {
        margin-top: 10px;
        font-size: 16px;
        color: #ffffff;
        border: 1px solid #0b7eff;
        border-radius: 4px;
        padding: 5px 0;
        background: linear-gradient(270deg, #1181ff 0%, #6db9ff 100%);
      }
      .mt_35 {
        margin-top: 35px;
      }
      .label_warp {
        // height: 167px;
        border-radius: 4px;
        margin: 10px 0;

        padding: 10px 5px 10px 5px;
        border: 1px solid #dbe9ff;
        img {
          margin-top: 10px;
          width: 202px;
          // height: 95px;
        }
      }
      .text_warp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 40px;
        font-size: 16px;
        font-weight: 400;
        span {
          margin-top: 5px;
        }
      }
    }
  }
}

// .tab_container {
//   width: 100%;
//   background: #f5f6fa;
//   .tab_warp {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: 1px solid #e4edfc;
//   }
//   .tab_item {
//     font-weight: 700;
//     font-size: 20px;
//     padding-bottom: 20px;

//     cursor: pointer;
//   }
//   .check {
//     position: relative;
//   }
//   .check::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 200px;
//     height: 4px;
//     background: #0060ff;
//   }
//   .content {
//     margin: 47px 0 70px 0;
//     display: flex;
//     img {
//       width: 742px;
//       height: 432px;
//       margin-left: 30px;
//     }
//     .cockpit_text {
//       margin-top: 36px;
//       text-align: left;
//       h3 {
//         color: #030d1e;
//         font-size: 20px;
//         font-weight: 700;
//         margin-bottom: 10px;
//       }
//       p {
//         font-weight: normal;
//         font-size: 16px;
//         line-height: 30px;
//       }
//     }
//   }
// }
.serve_warp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .serve_item {
    height: 240px;
    max-width: 281px;

    box-sizing: border-box;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    font-weight: 400;
    padding: 48px;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 72px;
      width: 72px;
    }
    h4 {
      margin-bottom: 5px;
      color: #0c1b35;
      font-size: 18px;
    }
    p {
      color: #808082;
    }
  }
}
.exper {
  margin: 0 auto;
  margin-top: 55px;
}
.bottom_bg {
  height: 554px;
  width: 100%;
  background: url("../assets/image/ProductApartment/bottom_bg.png") no-repeat
    center;
  background-size: cover;
  padding-top: 64px;
  text-align: center;
  .exhibition_warp {
    padding: 0 20px 40px 90px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .exhibition_item {
      text-align: center;
      h4 {
        color: #3d3d3d;
        font-size: 20px;
        margin: 10px 0;
      }
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 16px;
        color: #545558;
      }
    }
  }
}
.mobile_solution {
  display: none;
}
@media screen and (max-width: 768px) {
  .page {
    width: 100vw;
    overflow: hidden;
  }
  .btn-size {
    height: 29px;
    width: 72px;
    font-size: 12px;
    font-weight: 700;
  }
  .bg_wrap {
    height: 312px;
    background: url("../assets/image/ProductApartment/banner_bg-mobile.png")
      no-repeat center;
    background-size: cover;
    background-position: 100% 44px;
    padding-left: 12px;
    h3 {
      padding-top: 52px;
      font-size: 18px;
    }
    h1 {
      color: #030d1e;
      font-size: 18px;
    }
    h4 {
      color: #768396;
      padding-top: 8px;
      font-size: 11px;
    }
    .btn_warp {
      width: 100%;
      margin: 20px 0 0;
    }
  }
  .mar-24 {
    margin: 0 24px;
  }
  .solution_part {
    width: 100%;
    padding-bottom: 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .bottom_warp {
      padding-bottom: 24px;
    }
    .product_item {
      margin: 0 12px;
      padding: 20px 24px;
      img {
        height: 48px;
        margin-right: 24px;
      }
      .item_left {
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .mar-flanks-6 {
    margin: 0 6px !important;
  }
  .solution_part {
    .board_warp {
      .board_top {
        padding: 12px 0;
        h3 {
          font-size: 16px !important;
        }
        p {
          font-size: 12px !important;
        }
      }
      .board_bottom {
        margin: 12px 0 28px 0;
        padding: 0 10px;
        height: 426px;
        h4 {
          font-size: 14px;
          margin: 16px 0 12px 0;
        }
        .board_sbutile {
          font-size: 12px;
        }
        .label_warp {
          padding: 12px;
          p {
            font-size: 12px;
            color: #768396;
            margin-bottom: 8px;
          }
          img {
            // height: 53px;
            width: 100%;
            margin-top: 8px;
          }
        }

        .text_warp {
          font-size: 12px;
          padding: 12px 16px;
        }
      }
    }
  }
  .serve_warp {
    margin: 0 12px;
    .serve_item {
      height: 150px;
      width: 45vw;
      margin-top: 12px;
      padding: 12px 18px;
      img {
        height: 40px;
        width: 40px;
      }
      h4 {
        font-size: 14px;
      }
      p {
        font-size: 11px;
      }
    }
  }
  .exper {
    margin-top: 24px;
  }
  .bottom_bg {
    // height: 448px;
    width: 100%;
    background: url("../assets/image/ProductApartment/bottom_bg_mobile.png")
      no-repeat center;
    background-size: cover;
    padding-top: 0;
    text-align: center;
    .exhibition_warp {
      width: 100%;
      padding: 0 40px;
      flex-wrap: wrap;
      .exhibition_item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 52px;
        .exhibition_text {
        }
        img {
          height: 47px;
          width: 47px;
          margin-right: 16px;
        }
        h4 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;
          margin: 0;
        }
        p {
          font-size: 12px;
          text-align: left;
        }
      }
    }
  }
}
</style>
